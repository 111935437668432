.modal-assign-campaign .contact__assign__modal__content_vv2{
    display: flex;
    grid-gap: 16px;
}
.modal-assign-campaign .contact__assign__modal__content_vv2 .contact__assign__modal__content_vv2__single{
    flex: 1;
}
.modal-assign-campaign .contact__assign__modal__content_vv2 .contact__assign__modal__content_vv2__single .form-control{
    background-color: rgba(255,255,255,0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: 3rem;
}
.modal-assign-campaign .assign_user__time__zone__wr{
    margin-bottom: 8px;
}
.modal-assign-campaign .success__radio__assign__campaign{
    display: inline-flex !important;
    align-items: center !important;
    cursor: pointer;
}

.modal-assign-campaign .contact__assign__modal__get__day__wr button.MuiButton-root{
    display: inline-flex !important;
}


.enhanceDataModal .assign__searchIcon {
    position: absolute;
    left: 12px;
    color: #929295;
    font-size: 24px!important;
    top: 10px;
}

.enhanceDataModal .customFormControlField {
    border: 1px solid #cdcdd2!important;
    border-radius: 6px!important;
    padding: 12px 12px 12px 42px!important;
    height: auto!important;
    box-shadow: unset!important;
    box-sizing: border-box!important;
    color: #929295!important;
    margin: 0!important;
    font-weight: 400;
}


.enhanceDataModal .assign__tag_search_wrapper{
    position: relative;
}

.enhanceDataModal .upload-single-item-main ul {
    background: #fff;
    padding: 2px 10px;
}

.enhanceDataModal .upload-single-item-main ul li {
    background: #f6f7fb;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 10px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8896a8;
    letter-spacing: .01em;
}

.enhanceDataModal.manage-tag-modal .custom-checkbox-wrapper {
    display: flex;
    align-items: center;
    color: #9e9e9e!important;
}

.enhanceDataModal.manage-tag-modal .custom-checkbox-icon {
    padding: 9px;
    cursor: pointer;
    display: inline-flex;
}

.enhanceDataModal.manage-tag-modal .custom-checkbox-icon svg {
    width: 26px!important;
    height: 26px!important;
}

.enhanceDataModal.manage-tag-modal .custom-checkbox-icon path {
    fill: #666dfb!important;
}

.enhanceDataModal .massage_send_close_button .close_btn,
.enhanceDataModal .massage_send_close_button .send_massage {
    background-color: var(--accent-color);
    color: var(--white);
    border-radius: 5px;
    padding: 12px 20px;
    margin-right: 35px;
    transition: all .3s;
    display: flex;
    gap: 10px;
    align-items: center;
}

.enhanceDataModal.globalModalWrapper .modalBody {
    padding: 20px;
    height: calc(100vh - 170px) !important;
    box-sizing: border-box;
}

.enhanceDataModal.globalModalWrapper .massage_send_close_button .close_btn {
    background-color: var(--red);
}