.campaign-search-list .campaign-search-results button.create_new_tag {
    background: transparent;
    border: 1px solid #ddd;
    padding: 8px;
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.campaign-search-list .campaign-search-results button.create_new_tag:hover {
    background: lightgrey;
}
.campaign-search-list .campaign-search-results .not_found_tag {
    margin-left: 15px;
    margin-top: 15px;
}

.campaign-search-list .campaign-search-results .all__tags__to__show__area {
    max-height: calc(100vh - 240px);
    padding-bottom: 0;
}

.search-bar-top-area2.search-bar-top-area .search_input_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border-radius: 5px;
}

.search-bar-top-area2.search-bar-top-area .search_input_wrapper .search-icon {
    padding: 10px;
    height: 100%;
    display: grid;
    place-items: center;
    color: #ddd;
}

.search-bar-top-area2.search-bar-top-area .search_input_wrapper input.ad__custom__input{
    margin: 0;
    padding: 0 !important;
}

.manage-tag-modal .search_input_wrapper__for__relatiive {
    position: relative;
}
.manage-tag-modal .assign__tag__search__loader .MuiCircularProgress-root{
    position: absolute;
    top:13px;
    right: 13px;
}

.input-color-black{
    color: black !important;
}

.manage-tag-modal .custom-checkbox-wrapper{
    display: flex;
    align-items: center;
    color: #9e9e9e !important;
}

.manage-tag-modal .custom-checkbox-icon{
    padding: 9px;
    cursor: pointer;
    display: inline-flex;
}

.manage-tag-modal .custom-checkbox-icon svg{
    width: 26px !important;
    height: 26px !important;
}

.manage-tag-modal .custom-checkbox-icon path{
    fill:#666dfb !important;
}

.enhanceDataModal .upload-single-item-main ul {
    background: #fff;
    padding: 2px 10px;
}

.enhanceDataModal .upload-single-item-main ul li {
    background: #f6f7fb;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 10px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8896a8;
    letter-spacing: .01em;
}

.enhanceDataModal.manage-tag-modal .custom-checkbox-wrapper {
    display: flex;
    align-items: center;
    color: #9e9e9e!important;
}

.enhanceDataModal.manage-tag-modal .custom-checkbox-icon {
    padding: 9px;
    cursor: pointer;
    display: inline-flex;
}

.enhanceDataModal.manage-tag-modal .custom-checkbox-icon svg {
    width: 26px!important;
    height: 26px!important;
}

.enhanceDataModal.manage-tag-modal .custom-checkbox-icon path {
    fill: #666dfb!important;
}

.enhanceDataModal .massage_send_close_button .close_btn,
.enhanceDataModal .massage_send_close_button .send_massage {
    background-color: var(--accent-color);
    color: var(--white);
    border-radius: 5px;
    padding: 12px 20px;
    margin-right: 35px;
    transition: all .3s;
    display: flex;
    gap: 10px;
    align-items: center;
}

.enhanceDataModal.globalModalWrapper .modalBody {
    padding: 20px;
    height: calc(100vh - 170px) !important;
    box-sizing: border-box;
}

.enhanceDataModal.globalModalWrapper .massage_send_close_button .close_btn {
    background-color: var(--red);
}