.global-sms-send-form .virtual-number-container .vn-loader{
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #1f3d786e;
}

.remove__icon {
    all: unset;
    border: 2px solid #d00!important;
    padding: 6px!important;
    width: 40px!important;
    height: 40px!important;
    line-height: 30!important;
    border-radius: 50%!important;
    text-align: center;
    position: absolute!important;
    min-width: unset!important;
    color: #d00!important;
    right: -14px;
    top: 0;
    background: #fff!important;
    box-shadow: 0 0 0 5px #fff;
}

.template_topbar.sms-template-options{

}

.quick_reply_template{
    border: 1px solid #e5e8ef!important;
    border-radius: 5px;
}

body .customFormControl.border-none, .global__send_modal_wrapper .template_topbar .sms-send-module-template.border-none{
    border: none!important;
}
.sms-template-options > div{
    width: max-content;
}


 


.sms-template-options .trigger__select .MuiSelect-select,
.sms-template-options  .sms-send-module-template,
.sms-template-options  .quick_reply_template p{  
    padding: 3px 15px;
    border-radius: 5px;
}



.global__send_modal_wrapper .template_topbar  .sms-template-options .upload_bitton.sms-send-module-template {
    border-bottom: 0!important;
    border-right: 0!important;
    box-shadow: none!important;
    min-height: inherit!important;
    padding: 0px 10px;
}
.global__send_modal_wrapper .template_topbar.sms-template-options .upload_bitton.sms-send-module-template > div{
    height: inherit!important;
    line-height: 30px;
    background-color: transparent;
    box-shadow: none;
    color: rgba(19,49,89,.65);
    border: 1px solid #e5e8ef!important;
    border-radius: 5px;
    margin-left: 5px;
}

.global__send_modal_wrapper .template_topbar.sms-template-options .upload_bitton.sms-send-module-template{
    /* min-width: 241px; */
    min-width: 360px;
    border: none!important; 
    padding: 0!important;
    border-bottom: 0!important;
    border-right: 0!important;
    min-height: 0!important;
}

.global__send_modal_wrapper .template_topbar.sms-template-options  .quick_reply_template p{
    border-bottom: 0!important;
    border-right: 0!important;
    min-height: inherit!important;
    padding: 5px 10px!important;
    color: rgba(19,49,89,.65);
}

.global__send_modal_wrapper .template_topbar.sms-template-options{
    top: 5px;
    background: #f6f7fb;
    left: 5px;
}
.global__send_modal_wrapper .global_send_template textarea.message-content {
    padding: 50px 20px 20px!important;
}
.global__send_modal_wrapper .template_topbar.sms-template-options  .customFormControl,
.global__send_modal_wrapper .template_topbar.sms-template-options .trigger__select{
    min-height: 0px;
    height: 0!important;
}

.global-send-overflow-y{
    /* min-height: 400px;
    max-height: 400px;
    overflow-y: auto; */
    padding-bottom: 20px;
}
