.custom_audio_player_independent {
    margin-left: 0;
}

.custom_audio_player_independent ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
  }
  
  .custom_audio_player_independent ul li {
    list-style-type: none;
    margin-right: 7px;
    vertical-align: middle;
  }
  
  .custom_audio_player_independent ul li.range-field {
    width: 100px;
  }

  .custom_audio_player_independent ul li.range-field {
    width: 60px;
  }
  .custom_audio_player_independent li.duration span {
    font-size: 12px;
  }
  .custom_audio_player_independent li.download-icon {
    display: inline-flex;
    align-items: center;
  } 
  .custom_audio_player_independent li.download-icon svg {
    height: 18px;
  } 
  
  .custom_audio_player_independent li.sound svg {
    width: 20px;
  }
  
  .custom_audio_player_independent svg {
    cursor: pointer;
  }
  .custom_audio_player_independent li.play-pause-btn span {
    display: inline-flex;
    align-items: center;
  }
  .custom_audio_player_independent li.play-pause-btn {
    display: inline-flex;
    align-items: center;
    width: 24px;
    height: 24px;
  } 


.custom_audio_player_independent
{
    margin-top: 15px;
}
.custom_audio_player_independent ul{
    align-items: center;
}
.custom_audio_player_independent ul > li + li{
    margin: 0 0 0 7px;
}
.custom_audio_player_independent ul li {
    margin-right: 0;
}
.custom_audio_player_independent  .message_audio input{
    width: 60px!important;
    padding: 0!important;
}
.custom_audio_player_independent   ul li span{
    padding:0;
}
.custom_audio_player_independent li  svg {
  display: flex;
  align-items: center;
}