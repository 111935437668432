.align-items-start {
    align-items: flex-start !important;
}
.global_modal_overflow_hidden .globalModalWrapper{
    /* overflow: hidden !important; */
}
.flex-1 {
    flex: 1 !important;
}
h3 {
    margin: 0;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
	display: none;
}

.global__send_modal_wrapper .selected-contact-container > div {
    padding-right: 10px;
}
.global__send_modal_wrapper .selected-contact-container {
	/* max-height: 60px; */
	padding-bottom: 0;
    word-break: break-all;
}

.global__send_modal_wrapper
	.virtual-number-container
	.ds__row
	.trigger__modal_title {
	flex: 1 1;
	margin: 0;
}

.global__send_modal_wrapper .ds__row .alt.customFormControl {
	flex: 2 1;
}

.globalModalWrapper .global__send_modal_wrapper .customFormControl.alt {
	margin-top: 0 !important;
}

.global__send_modal_wrapper .MuiFormControl-root {
	border: 0;
	margin: 0;
	display: inline-flex;
	padding: 0;
	position: relative;
	min-width: 0;
	flex-direction: column;
	vertical-align: top;
}

.global__send_modal_wrapper .customFormControl {
	border: 1px solid #e5e8ef !important;
	border-radius: 6px !important;
	padding: 0 12px !important;
	box-shadow: unset !important;
	width: 100% !important;
	box-sizing: border-box !important;
	margin: 0 !important;
	min-height: 45px;
	display: inline-flex;
	justify-content: center;
}

.global__send_modal_wrapper .ds__system_defined {
	margin-top: 15px;
	border: 1px solid #ddd;
	border-radius: 6px;
}

.global__send_modal_wrapper
	.ds__system_defined
	.MuiFormControl-root.form-control {
	border: 1px solid #ddd;
	border-radius: 0;
	padding: 0 10px;
	border-top: 0;
	border-left: 0;
	border-bottom-right-radius: 6px;
}

.global__send_modal_wrapper .ds__followup {
	margin-top: 15px;
	border: 1px solid #ddd;
	border-radius: 6px;
	padding: 10px;
}

.global__send_modal_wrapper
	.ds__followup
	.trigger__modal_title:not(.ds__alter) {
	text-align: center;
	font-weight: 700;
	font-size: 18px;
}

.global__send_modal_wrapper .trigger__modal_title {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 10px;
}
.time-setting-tabs {
	justify-content: center;
	display: flex;
}

.global__send_modal_wrapper .modal__footer_btn button,
.global__send_modal_wrapper .ds__followup button {
	border: 1px solid #ddd !important;
	border-radius: 6px !important;
	margin: 0 5px 0 0 !important;
	min-height: 45px;
	box-shadow: unset !important;
    transition: all 0.3s linear;
    cursor: pointer;
}
.global__send_modal_wrapper .modal__footer_btn button:focus,
.global__send_modal_wrapper .modal__footer_btn button:hover,
.global__send_modal_wrapper .ds__followup button.active,
.global__send_modal_wrapper .ds__followup button.Mui-selected,
.global__send_modal_wrapper .ds__followup button:focus {
	/* background: #3c7ef3 !important; */
	color: #fff;
}
.global__send_modal_wrapper .ds__send_immediately {
	font-size: 16px;
	text-align: center;
	padding: 20px;
}
.global__send_modal_wrapper .ds__custom_date_wrapper {
	padding: 20px 0 0;
}
.global__send_modal_wrapper .modal__footer_btn button {
    margin: 0 !important;
    min-width: 100px;
}

.global__send_modal_wrapper .react-datepicker__input-container .trigger__modal_textField {
    margin: 0 !important;
    box-shadow: unset !important;
}
/*  */

.global__send_modal_wrapper .global_send_template {
	position: relative;
}

.global__send_modal_wrapper .global_send_template textarea::placeholder {
	color: #202021;
}

.global__send_modal_wrapper .global_send_template textarea {
	padding: 60px 20px 20px;
	height: auto;
	border: 1px solid #e5e8ef;
	border-radius: 6px;
	resize: unset;
	color: #202021;
	box-shadow: unset;
	outline: 0;
}

.global__send_modal_wrapper .template_topbar {
	display: flex;
	align-items: center;
	position: absolute;
}

.global__send_modal_wrapper .template_topbar .customFormControl {
	border-top: 0 !important;
	border-left: 0 !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	border-radius: 0 !important;
}

.global__send_modal_wrapper .template_topbar .sms-send-module-template {
	margin: 0 !important;
	border-bottom: 1px solid #e5e8ef ;
	border-right: 1px solid #e5e8ef ;
	min-height: 45px;
	display: inline-flex;
	align-items: center;
	padding: 10px;
	border-bottom-right-radius: 6px;
    cursor: pointer;
}

.global__send_modal_wrapper
	.template_topbar
	div#gloabal-sms-send-personalized-selector {
	font-size: 14px;
}


.global__send_modal_wrapper .ds__custom_date_wrapper .customFormControl {
    padding: 0 !important;
    /* border: 0 !important; */
	margin: 0 !important;
}

.global__send_modal_wrapper .ds__custom_date_wrapper .customFormControl.alt.alt {
    border: 1px solid #e5e8ef ;
    padding-left: 12px ;
}
.border-1{
	border: 1px solid #e5e8ef!important;
}
.height-48{
	height: 48px;
}
.global__send_modal_wrapper .trigger__modal_textField,
.global__send_modal_wrapper .addAppointment_wrapper select {
    border: 0 !important;
}

.topOption p {
    margin: 0;
}

.global_send_module_singleItem + .global_send_module_singleItem {
    margin-top: 10px;
}
.global_send_module_singleItem p {
    margin-top: 0;
    margin-bottom: 0;
}
.global_send_module_singleItem p + p {
    margin-top: 10px;
}

.global_send_module_singleItem {
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    
}

.global__send_modal_wrapper .back_button {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 10px 15px;
    min-width: 50px;
    min-height: 45px;
    cursor: pointer;
}

.email__template_desc {
    padding-bottom: 0;
    max-height: 40px;
}

.send-voice-mail-submit .btn{
	padding: 5px 23px;
    height: auto;
}
.btn.btn-primary{
	background-color: var(--light_blue);
}

.voice-mail-toolbar-tab > div{
	background-color: #E5E8EF;
	padding: 5px 20px;
	border-radius: 8px;
	cursor: pointer;
}
.voice-mail-toolbar-tab .voice-record.active{
	background-color: var(--light_blue);
	color: #FFF;
}
.voice-mail-toolbar-tab .voice-pre-template.active{
	background-color: var(--light_blue);
	color: #FFF;
}
.voice-audio-lists {
	max-height: 300px;
	margin:15px 0 0 0;
}
.voice-audio-lists > div + div{
	margin:10px 0 0 0 ;
}
.voice-audio-list{
	background-color: rgba(60, 126, 243, 0.1);
    padding: 10px;
    border-radius: 10px;

}
.voice-mail-list-left {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 0 10px 0 0;
}
.voice-mail-vn > p{
	margin-right: 20px;
}

/* design modify */
.global_send_module_singleItem .sms-template-title{

}

.global_send_module_singleItem .sms-template-message{
	font-size: 12px;
    color: #8a8484;
}

.unset-flex{
	flex: unset !important;
}


/* Select Action Modal */
.mapFinder__select_action .ppc__search_modal_wrapper.select_action_modal {
    width: auto;
    margin-top: 0px;
}
.mapFinder__select_action .search__contracts {
    margin-bottom: 0px !important;
}

.mapFinder__select_action .global__radioFormGroup:not(.second_radioFormGroup) label + label {
	margin-top: 10px;
}
.mapFinder__select_action .global__select_form_control {
    margin-top: 10px;
}
.mapFinder__select_action .secondRadioOption.global__select_form_control .MuiFormControl-root {
	margin-bottom: 8px;
}
/*
* TODO: this will go to instance send modal
*/
.v3__instance__modal .instance__body .instance__item:hover,
.v3__instance__modal .instance__body .instance__item.active {
        border: 1px solid #d0d0d0;
        background: #eeeeee;
}
.v3__instance__modal .instance__body .instance__item:hover {
	cursor: pointer;
}

/* Send SMS Modal */
.v3__send_sms .global__send_modal_wrapper .customFormControl {
	padding: 0 5px 0 0 !important;
}
.global__send_module_modal .global__send_modal_wrapper .ds__custom_date_wrapper .customFormControl.alt.alt {
	min-height: 50px;
}
.v3__send_sms .global-send-overflow-y {
    min-height: unset;
    max-height: unset;
    overflow-y: unset;
    padding-bottom: 0px;
}
.global__send_module_modal .trigger__modal_textField {
	height: 50px;
	padding: 0 !important;
}

.global__send_module_modal .global__send_modal_wrapper .ds__followup button {
	min-height: 50px;
}

.global__send_modal_wrapper.v3__send_voice_mail .ds__followup .trigger__modal_title:not(.ds__alter),
.global__send_module_modal .global__send_modal_wrapper .ds__followup .trigger__modal_title:not(.ds__alter) {
	padding-top: 10px;
	padding-bottom: 6px !important;
}
fieldset.MuiFormControl-root.v3__save_as_template {
    display: block;
    margin: 10px 0;
}

fieldset.MuiFormControl-root.v3__save_as_template label {
    margin: 0;
}

fieldset.MuiFormControl-root.v3__save_as_template .MuiFormControlLabel-label {
    margin-left: 8px;
}
.v3__send_sms .global__send_modal_wrapper .global_send_template textarea.message-content {
    padding-left: 5px !important;
	height: 170px;
}

.v3__send_sms .global__message_img_wrapper {
    position: relative;
    max-width: 250px;
    background: #fff;
    padding: 10px;
    border-radius: 6px;
    margin-top: 10px;
}

.v3__send_sms .global__message_img_wrapper button.MuiButtonBase-root.MuiButton-root.MuiButton-text.remove__icon {
    width: 30px !important;
    height: 30px !important;
    top: -6px;
    right: -6px;
    box-shadow: 0px 0px 0px 0px #000;
}
.v3__send_sms .MuiInputAdornment-root.MuiInputAdornment-positionEnd,
.v3__send_sms .trigger__modal_textField input {
	height: auto;
}
 /* TODO: need to fix - Fixed */
.global__send_modal_wrapper .ds__followup .firstFlex button {
    margin: 0 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
    border-radius: 0 !important;
}
.v3__send_sms .trigger__modal_textField .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl {
    height: 50px;
    box-sizing: border-box;
}

.v3__send_sms .trigger__modal_textField .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl input {
    padding-top: 10px;
}
.global__send_modal_wrapper .ds__followup button:focus {
	color: rgba(0, 0, 0, 0.54);
}
.v3.global_send_template p {
	margin: 0;
}
.sendModulesButton {
    background-color: var(--accent-color);
    color: var(--white);
    border-radius: 5px;
    padding: 12px 20px;
    margin-right: 35px;
    transition: all .3s;
	margin: 0 10px;
	text-transform: capitalize;
    font-size: 16px;
	border: 0;
	cursor: pointer;
}
.cancelButton {
	background-color: var(--red);
}
.sendModulesButton:focus {
    background-color: var(--accent-color) !important;
    color: var(--white);
}
.cancelButton:focus {
	background-color: var(--red) !important;
}
/* Send Email */
.global__send_modal_wrapper .ds__system_defined div#gloabal-email-send-personalized-selector-subject:focus {
    background: transparent !important;
}
.v3__send_email .subject-container-alt > input::placeholder {
	color: #133159;
}
.v3__send_email .subject-container-alt > input {
	font-family: var(--poppins) !important;
}

.v3__send_email .subject-container-alt > input:focus {
	box-shadow: unset !important;
	border-bottom: 0 !important;
}

.v3__send_email .trigger__select .MuiSelect-select em {
	font-style: unset !important;
}

.v3__send_email .subject-container-alt > div {
        width: 160px !important;
}

/* Voice Send Modal */
.v3__send_voice_mail .border-1 {
    min-height: unset!important;
    border: 1px solid #e5e8ef!important;
    padding: 0 !important;
    background: #f6f7fb;
    border-radius: 5px;
    color: rgba(19,49,89,.65);
    font-family: Poppins;
    font-weight: 400!important;
    font-size: 16px;
    line-height: 24px;
    margin: 0 !important;
}

.v3__send_voice_mail .border-1 .MuiSelect-root {
    padding: 12px 15px;
    padding-right: 24px;
}

.v3__send_voice_mail .border-1 .MuiSelect-root + input {
    height: auto;
}

.v3__send_voice_mail .voice-mail-vn {
    margin-bottom: 10px;
}

.v3__send_voice_mail .voice-mail-toolbar-tab > div {
	padding: 10px 20px;
    border-radius: 5px;
}
.global__send_modal_wrapper .record_message_template {
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
    box-shadow: 1.5px 1.5px 0px #c5c5c5;
}

.global__send_modal_wrapper .alert__message {
    background: #dddddd7a;
    border-radius: 5px;
    padding: 10px;
    box-shadow: -1.5px -1.5px 0px #c5c5c5;
	max-width: 290px;
}

.global__send_modal_wrapper .voice_mail_icon {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    min-width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-items: center;
	flex-direction: column;
}

.global__send_modal_wrapper .voice_mail_icon p {
    margin: 0;
}
.v3__send_voice_mail .btn.btn-primary {
        background-color: var(--accent-color);
}

/* Video Email  */
.fromEmail { 
	min-height: unset!important;
	border: 1px solid #e5e8ef!important;
	padding: 9px 15px;
	background: #f6f7fb;
	border: 1px solid #e5e8ef;
	border-radius: 5px;
	color: rgba(19,49,89,.65);
	font-family: Poppins;
	font-weight: 400!important;
	font-size: 16px;
	line-height: 24px;
}
.v3__video_email_field_wrapper .customLabelWidth {
	width: 70px;
}

.v3__video_email_alt .customWidth.fromEmail {
    display: flex;
    align-items: center;
	justify-content: space-between;
    width: calc(100% - 70px);
}
.v3__video_email_alt .input-field.subject_input.selects_fields {
    margin: 0;
}
.v3__video_email_alt .input-field.subject_input.selects_fields input:focus{
	box-shadow: unset !important;
	border: 0;
}
.v3__video_email_alt .input-field.subject_input.selects_fields input {
	margin: 0;
	height: auto;
	border: 0;
	box-shadow: unset !important;
}
.v3__video_email_alt .MuiSelect-select:focus {
    background-color: unset !important;
}
.v3__video_email_field_wrapper + .modal__scroll .video_contents {
	margin: 0;
}

.customVideoEmailPersonalizeWrap {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 9px 12px;
    margin-left: -10px;
}

.global__send_modal_wrapper .video_contents .video_email_bg_icon {
    padding: 5px 10px !important;
    border: 1px solid #ddd;
    border-radius: 5px;
	background: #2c6b65 !important;
    color: #fff;
    border: 0;
}

.global__send_modal_wrapper .voice_mail_icon {
	min-width: 90px;
}

.global__send_modal_wrapper .video_preview_side {
    height: 208px;
}

.global__send_modal_wrapper .timer span {
	font-size: 18px;
}
.global__send_modal_wrapper .video_preview_side .likeArea input::placeholder,
.global__send_modal_wrapper .video_preview_side .likeArea textarea::placeholder {
	color: #546376;
}
.global__send_modal_wrapper .video_preview_side .likeArea input,
.global__send_modal_wrapper .video_preview_side .likeArea textarea, 
.global__send_modal_wrapper .video_preview_side .likeArea select {
    padding: 4px 12px;
    height: 36px;
}

.global__send_modal_wrapper .likeArea textarea {
    margin: 0 0;
}
.voice-mail-list-right {
    word-break: break-all;
}

/* Update styles - Oct 18 */
.globalModalWrapper.instance__modal.updated_instance__modal {
	min-width: unset !important;
	max-width: 1200px;
	width: 100%;
	height: calc(100% - 85px);
}

.globalModalWrapper.updated_instance__modal .instance__body {
	justify-content: center;
}
.instance__body .instance__item {
	cursor: pointer;
}

.global-card-broadcast-modal .updated_templete-img-main .tem-single-item {
	max-height: unset;
	height: 115px;
}

.global-card-broadcast-modal .save_postcard_topbar .margin-none-row.text-left {
        text-align: left;
}

.trigger__modal_textField .MuiInput-formControl.MuiInputBase-adornedEnd{
	height: 100% !important;
}

.instance__modal .send__drip__eastern__time__flex1,
.instance__modal .send__drip__eastern__time__v2{
	flex: 1;
}
.instance__modal .send__drip__process__type__batch__wr{
	display: flex;
	grid-gap: 16px;
}
.instance__modal .send__drip__process__type__batch__wr .send__drip__process__type__batch__single{
	flex: 1;
}
.instance__modal .send__drip__process__type__batch__wr .send__drip__process__type__batch__single .form-control{
	background-color: rgba(255,255,255,0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: 3rem;
}
.instance__modal .align__item__center__v2{
	align-items: center;
}

.instance__modal .contact__assign__modal__get__day__wr button.MuiButton-root{
    display: inline-flex !important;
}