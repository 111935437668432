.MapFinderModule-container-parent-class .MuiCheckbox-root, .MuiRadio-root {
	padding: 0;
}

.MapFinderModule-container-parent-class .customMapWrapper .MuiIconButton-label .MuiSvgIcon-root {
	width: 18px;
	height: 18px;
	margin-right: 8px;
}

.MapFinderModule-container-parent-class .customMapWrapper {
	width: 100%;
	display: block;
	background-color: #fff;
	border-radius: 10px;
}

.MapFinderModule-container-parent-class .customMapWrapper h2 {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	display: flex;
	align-items: center;
	letter-spacing: 0.01em;
	color: #133159;
	padding: 0px 20px;
	border-bottom: 1.39px solid #e7eff0;
	margin: 0px 0px 20px 0px;
}

.MapFinderModule-container-parent-class .customMapWrapperForm {
	padding: 0 20px;
}

.MapFinderModule-container-parent-class .ppc__searchSelect, .MapFinderModule-container-parent-class .ppc__searchSelect input {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px !important;
	line-height: 22px;
	letter-spacing: 0.01em;
	border: 1px solid #546376 !important;
	color: #a6acbe;
	border-radius: 0 !important;
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
	height: 50px !important;
	padding: 14px 18px !important;
	box-shadow: unset !important;
	outline: 0 !important;
	margin: 0 !important;
	box-sizing: border-box !important;
	background: #f6f7fb !important;
}

.MapFinderModule-container-parent-class .ppc__searchSelect .MuiInputBase-root {
	padding: 0 !important;
	border-radius: 0 !important;
}

.MapFinderModule-container-parent-class .Mui-focused input, .MapFinderModule-container-parent-class .ppc__searchSelect input:focus {
	box-shadow: unset !important;
	outline: 0 !important;
	border: 0 !important;
}

.MapFinderModule-container-parent-class .customMapWrapper .customMap {
	/* height: calc(100vh-280px); */
	background: #f8f8f8;
	width: 100%;
	margin-top: 20px;
}

.MapFinderModule-container-parent-class .ppc__searchSelect_btn {
	padding: 14px 20px !important;
	background: #3c7ef3 !important;
	border-radius: 0px 5px 5px 0px !important;
	font-family: Poppins !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 22px !important;
	letter-spacing: 0.01em !important;
	color: #ffffff !important;
	width: 126px !important;
	min-width: unset !important;
	height: 50px;
}

.MapFinderModule-container-parent-class .MuiButtonBase-root {
	text-transform: capitalize;
}

.ppc__search_modal .ppc__blue_btn:hover {
	background: #3c7ef3bd !important;
}

.ppc__searchSelect_with_input {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.ppc__searchSelect {
	width: calc(100% - 126px);
}

.MapFinderModule-container-parent-class .customMapWrapper .MuiAutocomplete-clearIndicator {
	display: none;
}

.MapFinderModule-container-parent-class .ppc__searchSelect_with_input p span {
	color: #3c7ef3;
	cursor: pointer;
}

.MapFinderModule-container-parent-class .ppc__searchSelect_with_input p {
	font-family: Poppins;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.02em;
	color: rgba(19, 49, 89, 0.5);
	margin: 15px 0 20px;
	display: inline-flex;
}

.MapFinderModule-container-parent-class .ppc__searchSelect_with_input p>svg {
	margin-right: 5px;
}

.MapFinderModule-container-parent-class .ppc__searchSelect_with_input .MuiFormControlLabel-label {
	font-family: Poppins;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.01em;
	text-transform: capitalize;
	color: rgba(19, 49, 89, 0.65);
	/* margin-left: 10px; */
	text-transform: capitalize;
}

/* Tag List Modal */

.globalModalWrapper {
	min-width: 685px !important;
}

.ppc__customCheckbox .MuiFormControlLabel-root+.MuiFormControlLabel-root {
	/* margin-left: 30px; */
	margin-right: 30px;
}

.ppc__customCheckbox label.MuiFormControlLabel-root:first-child {
	margin-right: 30px;
}

.ppc__customCheckbox label.MuiFormControlLabel-root {
	margin: 0;
}

.ppm_tags__modal .tagList__checkbox_wrapper ul {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-row-gap: 15px;
	row-gap: 15px;
	width: 100%;
	max-height: 221px;
	overflow-y: auto;
	padding: 20px;
	background: #fff;
	border-radius: 5px;
	grid-column-gap: 20px;
}

.ppm_tags__modal .ppc__searchSelect_with_input {
	align-items: flex-start;
}

.ppm_tags__modal .ppc__customCheckbox .MuiFormControlLabel-root+.MuiFormControlLabel-root {
	margin-left: unset;
}

.ppm_tags__modal .MuiCheckbox-root {
	padding: 0;
}

.ppm_tags__modal .ppc__searchSelect_with_input .MuiFormControlLabel-label {
	margin-left: 5px;
}

.ppc__customCheckbox .ppc__select_all {
	margin-bottom: 15px !important;
}

.ppc__search_modal_wrapper.select_action_modal {
	/* width: 340px; */
	margin-top: 20px;
}

.ppc__blue_btn {
	font-family: Poppins !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 24px !important;
	display: flex !important;
	align-items: center !important;
	letter-spacing: 0.01em !important;
	padding: 10px 15px !important;
	color: #ffffff !important;
	background: var(--accent-color);
	height: 50px;
	width: 207px;
}

.search__contracts {
	margin-top: 30px !important;
	margin-bottom: 10px !important;
}

.ppm_tags__modal .ppc__searchSelect_with_input .MuiFormControlLabel-label {
	color: #133159;
	font-weight: normal !important;
	line-height: 30px;
}

/* Search Modal */

.ppc__search_modal .ppc__blue_btn {
	padding: 10px 10px !important;
	width: 220px !important;
	margin-top: 20px !important;
}

.distance_text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.01em;
	color: #6258ff;
	margin-top: 5px;
	display: inline-block;
	cursor: pointer;
}

.ppc__search_modal .MuiFormGroup-root+.MuiFormGroup-root {
	margin-top: 10px;
}

.ppc__search_modal .ppc__search_modal_wrapper .MuiInputBase-input {
	background: #f6f7fb;
	border: 1px solid #e5e8ef;
	box-sizing: border-box;
	border-radius: 5px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.01em;
	color: rgba(19, 49, 89, 0.65);
	margin: 0;
	padding-left: 15px;
	height: 50px;
}

.ppc__search_modal .globalModalWrapper .modalBody {
	background: #fff;
}

body .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border-color: #e5e8ef !important;
}

.ppc__search_modal fieldset, .MuiOutlinedInput-root.Mui-focused fieldset:hover, .MuiOutlinedInput-root.Mui-focused fieldset {
	border: 1px solid #e5e8ef !important;
	display: none;
}

.ppc__search_modal .ppc__search_modal_wrapper .MuiInputBase-input:focus {
	box-shadow: unset !important;
	outline: 0 !important;
	border: 1px solid #e5e8ef !important;
}

.ppc__search_modal label.MuiFormLabel-root {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	color: #133159;
	margin-bottom: 5px;
}

.ppc__search_modal .globalModalWrapper {
	min-width: unset !important;
	width: 603px;
	right: unset !important;
}

.globalModalWrapper.globalCenterModalWrapper {
	border-radius: 10px;
}

.ppc__search_modal .globalModalWrapper .sub_title h2 {
	font-size: 16px;
	line-height: 24px;
	margin: 0;
}

/* Select Action Modal */

.ppm_select_action_modal .globalModalWrapper {
	min-width: unset !important;
	width: 430px;
	right: unset !important;
}

.global__radioFormGroup label, .global__radioFormGroup label .MuiFormControlLabel-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: rgba(19, 49, 89, 0.65);
	margin-left: 8px;
}

.global__radioFormGroup label {
	margin-left: 0;
	display: inline-flex;
}

.global__radioFormGroup svg.MuiSvgIcon-root {
	color: #3c7ef3;
}

.ppm_select_action_modal .modalBody {
	margin-bottom: 30px;
}

.ppm_select_action_modal .MuiFormControlLabel-root {
	margin: 0 !important;
}

.ppm_select_action_modal .MuiFormControlLabel-root+.MuiFormControlLabel-root {
	margin-top: 15px !important;
}

.ppm_select_action_modal .MuiFormControlLabel-root .MuiFormControlLabel-label label span {
	color: #ff264a;
}

.ppm_select_action_modal .errorOutlineIcon {
	margin-left: 10px;
}

.errorOutlineIcon {
	cursor: pointer;
}

.MuiInput-underline:before, .MuiInput-underline:after {
	display: none;
}

.global__select_form_control {
	margin: 15px 0 10px;
	padding-left: 20px;
	box-sizing: border-box;
}

.global__select_form_control .MuiSelect-select {
	background: #ffffff;
	border: 1px solid #e5e8ef;
	box-sizing: border-box;
	border-radius: 5px;
	height: 50px;
	width: 100%;
	padding: 13px 10px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: rgba(19, 49, 89, 0.65);
	/* margin: 15px 0 10px; */
}

.ppc__search_modal_wrapper .MuiOutlinedInput-input {}

.ppc__search_modal_wrapper .MuiFormControl-root {
	width: 100%;
}

.MuiSelect-select:focus {
	border-radius: 5px !important;
	background-color: #fff !important;
}

.ppm_select_action_modal .globalModalWrapper .sub_title h2 {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	margin: 0;
}

.ppm_select_action_modal .mt10px {
	margin-top: 10px !important;
}

.global__select_form_control .MuiFormControlLabel-root+.MuiFormControlLabel-root {
	margin-top: 10px !important;
}

/* Sending Process Modal */

.sending_process_modal .globalModalWrapper {
	min-width: unset !important;
	width: 430px;
	right: unset !important;
}

.sending_process_modal_wrapper {
	width: 450px;
	height: 125px;
}

.sending_process_modal_wrapper .search__contracts {
	padding: 13px 20px !important;
	margin: 0 10px;
}

.sending_process_modal .search__contracts {
	margin-top: 0px !important;
	margin-bottom: 10px !important;
}

.sending_process_modal .search__contracts+.search__contracts {
	margin-left: 20px;
}

.common__modal .globalModalWrapper .sub_title h2 {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #133159;
}

/* Global Title */

.global__modal_subtitle {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #133159;
	margin-bottom: 5px;
	display: inline-block;
}

.global__input_text_field input {
	background: #ffffff !important;
	border: 1px solid #e5e8ef !important;
	border-radius: 5px !important;
	height: 45px !important;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: rgba(19, 49, 89, 0.65);
	padding-left: 10px !important;
}

.global__input_text_field fieldset {
	display: none;
}

.set_order_modal .globalModalWrapper {
	min-width: unset !important;
	width: 410px;
	right: unset !important;
}

/* Instance Send Modal */

.instance__modal .globalModalWrapper {
	width: 861px;
	min-width: unset !important;
}

.instance__header {
	margin-bottom: 20px;
	border-bottom: 1px solid #f6f7fb;
	padding-bottom: 10px;
}

.instance__header h5 {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: #133159;
	margin: 0;
}

.instance__header h5 span {
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.01em;
	color: rgba(19, 49, 89, 0.65);
	display: block;
}

.credit_each {
	background: #68ebca;
	border-radius: 5px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;
	display: flex;
	align-items: center;
	letter-spacing: 0.01em;
	color: #ffffff;
	padding: 10px;
}

.credit_each span {
	font-size: 16px;
	line-height: 12px;
}

.instance__modal .globalModalWrapper .modalBody {
	padding-top: 10px;
}

.instance__modal .globalModalWrapper {
	background: #fff;
}

.instance__modal .globalModalWrapper .massage_send_close_button, .instance__modal .globalModalWrapper .globalModalTitle {
	border-width: 1px;
	border-color: #e5e8ef;
}

.circle {
	border-radius: 50%;
}

.instance__body {
	display: flex;
	flex-wrap: wrap;
	column-gap: 20px;
	row-gap: 20px;
}

.instance__body .instance__item .instance__round_icon {
	background: #e5e8ef;
	width: 50px;
	height: 50px;
}

.instance__body .instance__item .instance__round_icon svg {
	color: #133159;
}

.instance__body .instance__item {
	background: #f6f7fb;
	border: 1px solid rgba(19, 49, 89, 0.05);
	box-sizing: border-box;
	border-radius: 10px;
	width: 190px;
	height: 115px;
}

.instance__body .instance__item .instance__text {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #133159;
	margin-top: 5px;
}

.instance__body .instance__item.active {
	border: 1px solid #3c7ef3;
}

.instance__body .instance__item.active .instance__round_icon {
	background: #3c7ef3;
}

.instance__body .instance__item.active .instance__round_icon svg {
	color: #fff;
}

.instance__body .instance__item.active .instance__text {
	color: #3c7ef3;
}

.instance__second_steps p {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: #133159;
	margin-bottom: 20px;
	border-bottom: 1px solid #f6f7fb;
	padding-bottom: 20px;
}

.globalModalWrapper .instance__gray_btn {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: #3c7ef3;
	padding: 12px 35px;
	height: 50px;
	background: #f6f7fb;
	border-radius: 5px;
}

/* Contact Details */

.contact__details_modal .globalModalWrapper .sub_title h2 {
	font-size: 20px;
	line-height: 30px;
}

.contact__details_modal .global__select_form_control {
	padding: 0;
}

.contact__first_steps {
	padding-bottom: 20px;
	margin-bottom: 10px;
	border-bottom: 1px solid #e5e8ef;
}

.contact__details_modal .contact__steps_wrapper .MuiFormGroup-root input, .contact__details_modal .contact__steps_wrapper .MuiFormGroup-root label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: rgba(19, 49, 89, 0.65);
	width: 220px;
}

.contact__steps_wrapper .MuiInputBase-input {
	margin: 0 !important;
	height: auto !important;
	border: 0 !important;
}

.contact__steps_wrapper .MuiFormGroup-root {
	flex-direction: row;
}

.contact__steps_wrapper .MuiFormGroup-root+.MuiFormGroup-root {
	margin-top: 15px;
}

.contact__details_modal .contact__steps_wrapper .alt .MuiFormGroup-root label {
	font-weight: 500;
	color: #133159;
	line-height: 24px;
	width: 100%;
	margin-bottom: 5px;
}

.contact__details_modal .contact__steps_wrapper .alt .MuiInput-root select {
	background: #f6f7fb;
	border: 1px solid #adb7c5 !important;
	box-sizing: border-box;
	border-radius: 5px;
	width: 100%;
	height: 50px !important;
	font-family: Poppins;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: rgba(19, 49, 89, 0.65);
	padding-left: 10px;
}

.twoColumns {
	border-bottom: 1px solid #e5e8ef;
	padding-bottom: 30px;
	margin-bottom: 10px;
}

.contact__details_modal .contact__steps_wrapper .alt .MuiInputBase-root {
	width: 100%;
}

.contact__details_modal .globalModalWrapper {
	background: #fff;
}

.twoColumns span.MuiButton-label svg {
	width: 20px;
	height: 20px;
	background: #fff;
	border-radius: 50%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	color: #3c7ef3;
}

.twoColumns .ppc__blue_btn {
	width: 133px;
	padding-left: 0;
	padding-right: 0;
}

.twoColumns .MuiFormGroup-root {
	width: calc(100% - 163px);
}

.contact__details_modal .globalModalWrapper .massage_send_close_button .send_massage, .contact__details_modal .globalModalWrapper .massage_send_close_button .close_btn {
	margin-right: 0 !important;
}

.contact__details_modal .globalModalWrapper .massage_send_close_button a+a {
	margin-left: 20px;
}

.contact__details_modal .contact__first_steps:not(.alt) .MuiFormControl-root {
	width: calc(100% - 220px);
}

.contact__details_modal .contact__first_steps:not(.alt) .MuiInputBase-root, .contact__details_modal .contact__first_steps:not(.alt) input {
	width: 100%;
}

/* Lead Process */


.MapFinderModule-container-parent-class
  .map__finder__search__wr
  button.MuiButtonBase-root {
	height: 100% !important;
	flex-shrink: 0;
}

.contact__details_modal .contact__steps_wrapper .alternative .MuiFormGroup-root label {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: #133159;
	width: 100%;
	margin-bottom: 5px;
	display: inline-block;
}

.contact__details_modal .contact__steps_wrapper .alternative .MuiFormGroup-root input {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: rgba(19, 49, 89, 0.65);
	margin: 0;
}

.contact__details_modal .contact__first_steps.alternative .MuiFormControl-root {
	width: 100%;
}

.contact__details_modal .contact__first_steps.alternative input {
	background: #ffffff;
	border: 1px solid #e5e8ef !important;
	box-sizing: border-box;
	border-radius: 5px;
	height: unset !important;
	padding-left: 10px !important;
	padding: 14px 12px 10px !important;
}

.contact__details_modal .contact__steps_wrapper .MuiFormGroup-root+.MuiFormGroup-root {
	margin-top: 20px;
}

.threeColumns {
	max-width: 595px;
	column-gap: 35px;
}

.threeColumns>div {
	width: 174px;
}

.contacts__datePicker {
	position: relative;
}

.contacts__datePicker input {
	padding-right: 30px;
}

.threeColumns .MuiSelect-select.MuiSelect-select {
	border: 1px solid #e5e8ef !important;
	height: 50px !important;
	border-radius: 6px;
	padding: 0 10px;
	font-size: 14px;
	line-height: 24px;
	color: rgba(19, 49, 89, 0.65);
}

.contacts__datePicker svg {
	position: absolute;
	right: 10px;
	color: gba(19, 49, 89, 0.65);
	top: 50%;
	transform: translateY(-50%);
}

.threeColumns .ppc__blue_btn {
	background: transparent !important;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: #133159 !important;
	width: 100% !important;
}

.sendOn__buttons_group {
	column-gap: 25px;
	display: inline-flex;
}

.sendOn button {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	display: flex;
	align-items: center;
	color: #546376;
	padding: 10px 12px;
	background: #f6f7fb;
	border: 1px solid #f6f7fb;
	border-radius: 5px;
}

.sendOn button.active {
	color: #546376;
	background: #f6f7fb;
	border-color: #3C7EF3;
}

.contact__first_steps.alternative {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: 0;
}

.contact__details_modal .contact__steps_wrapper .alternative label span {
	color: #d00;
}

.drp_locations {
	flex-direction: row !important;
	margin-left: 20px !important;
	column-gap: 15px;
}

.drp_locations .MuiInputBase-root {
	border: 1px solid #e5e8ef;
	padding: 5px 20px;
	font-size: 14px;
	color: #133159;
	font-family: 'Poppins' !important;
	font-style: normal;
	border-radius: 5px;
	height: 50px;
}

.drp_locations span.MuiChip-label {
	font-size: 16px !important;
	color: #fff;
	font-weight: 400;
	padding: 13px 10px;
	font-family: 'Poppins' !important;
}

.drp_locations .MuiSelect-outlined.MuiSelect-outlined {
	font-family: 'Poppins' !important;
}

.drp_locations .MuiSelect-outlined.MuiSelect-outlined em {
	font-style: normal !important;
	font-size: 16px;
	color: #133159 !important;
}

.drp_locations .MuiChip-root {
	border-radius: 5px !important;
	background-color: #3c7ef3 !important;
	height: 28px;
}

.drp_locations button.MuiButtonBase-root {
	padding: 10px 30px !important;
	font-size: 18px !important;
	background-color: #3c7ef3 !important;
}

.drp_locations .makeStyles-chips-2 {
	column-gap: 10px;
}

.gmnoprint button:focus {
	background: white !important;
}

.ppc__search_modal_in_map .new-loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 60px;
	height: 60px;
	display: block;
}

.ppc__search_modal_in_map .new-loader>div {
	margin: 0;
	height: 60px;
}

.ppc__search_modal_in_map .new-loader button {
	all: unset;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 60px;
	left: 50%;
	display: block;
	text-align: center;
}

.ppc__search_modal_in_map .new-loader .MuiCircularProgress-root {
	width: 60px !important;
	height: 60px !important;
	margin: 0 !important;
	top: unset;
	left: unset;
	position: relative;
}

.MapFinderModule-container-parent-class .infowindow-more-info-button, .MapFinderModule-container-parent-class .infowindow-more-info-For-saved-Contact, .MapFinderModule-container-parent-class .infowindow-findNeighbour {
	background-color: var(--light_blue);
	color: var(--white);
	border-radius: 5px;
	padding: 12px 20px;
	/* margin-right: 35px; */
	margin: 5px;
	transition: all .3s;
	margin: 0 auto;
	text-align: center;
	cursor: pointer
}

.MapFinderModule-container-parent-class .preview-data-action form .MuiFormGroup-root>label {
	margin-bottom: 5px;
}

.MapFinderModule-container-parent-class .preview-data-action .global__select_form_control {
	margin: 0px 0 0px;
}

.MapFinderModule-container-parent-class .preview-data-action .global__select_form_control .MuiFormControl-root {
	margin-bottom: 5px;
}

.MapFinderModule-container-parent-class .preview-data-action .global__select_form_control .MuiFormGroup-root {
	margin: 0px 0 0px;
}

.MapFinderModule-container-parent-class .preview-data-action .global__select_form_control .MuiFormControlLabel-root+.MuiFormControlLabel-root {
	margin-top: 0!important;
}

/* custom css */

.contact__steps_wrapper .MuiFormGroup-root label {
	padding: 0 15px 0 0;
}

.map-finder-contact-details .MuiFormGroup-root label {
	width: 35%;
}

/*.map-finder-contact-details .MuiFormGroup-root .MuiFormControl-root {*/
/*	width: 65%;*/
/*}*/

.contact__steps_wrapper .MuiFormGroup-root label, .contact__steps_wrapper .MuiInputBase-input {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: rgba(19, 49, 89, 0.65);
}

.contact__steps_wrapper .MuiInputBase-input {}

.contact__steps_wrapper .MuiFormGroup-root {
	/* justify-content: space-between; */
	/* border-bottom: 1px dashed currentColor; */
}

.contact__first_steps.alt .MuiFormGroup-root {
	border-bottom: 0;
}

.ppc__search_modal_wrapper .MuiOutlinedInput-input {
	border-bottom: none!important;
	padding: 10px 20px!important;
}

.w-100 {
	width: 100%!important;
}

.MapFinderModule-container-parent-class .customMap .gm-style .gm-style-iw-d {
	margin: 0 auto;
	text-align: center;
}

.contact__first_steps .mpf-select {
	width: 100%;
}

.contact__steps_wrapper .mpf-select .MuiInputBase-input {
	background: #F6F7FB;
	border: 1px solid #ADB7C5!important;
	border-radius: 5px;
	padding: 8px 10px;
}

.contact__steps_wrapper .mpf-select .MuiInputBase-input:focus {
	border-color: #133159;
	background: #F6F7FB;
}

.bg-white {
	background-color: #fff!important;
}

.globalModalWrapper.cardBordcastTab.mpf-contact-details-modal {
	max-width: 700px;
	width: 100%;
	height: calc(100% - 80px);
}
.contactDetailsGlobalModal{
	max-width: 600px !important;
}

.dark_blue_color {
	color: #133159!important;
}

.font-500 {
	font-weight: 500!important;
}

.ppc__search_modal_in_map .ppc__search_modal_wrapper label {
	color: #133159;
}

.fixed-height-100-80 {
	height: calc(100% - 80px);
}

/* Tag modal */

.tag-component-loading .new-loader>div {
	margin: auto;
	display: block;
}

.tag-component-loading .new-loader>div button {
	height: 50px;
	background: transparent;
}

/* Map Topbar design */

.customMapWrapperForm_oct_12 .flexRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 10px 0;
	background: #eee;
	margin: 10px 0;
	border-radius: 5px;
}

.customMapWrapperForm_oct_12 .flexRow .flexItem1 {
	flex: 0 1 calc(50% - 10px);
}

.customMapWrapperForm_oct_12 .flexItem1Left {
	display: inline-flex;
	align-items: center;
}

.customMapWrapperForm_oct_12 .ppc__searchSelect_with_input p {
	font-size: 13px;
	margin: 15px 0;
	color: rgb(19 49 89 / 61%);
}

.MapFinderModule-container-parent-class .customMapWrapper h2 {
	font-size: 20px;
	line-height: 20px;
	border: 0;
	margin: 0;
	padding-left: 0;
}

.MapFinderModule-container-parent-class .customMapWrapperForm_oct_12 .ppc__searchSelect_btn, .customMapWrapperForm_oct_12 .ppc__searchSelect {
	height: 45px !important;
	padding: 10px 18px !important;
}

.MapFinderModule-container-parent-class .customMapWrapper .customMap {
	margin-top: 10px;
}

.MapFinderModule-container-parent-class .customMapWrapperForm {
	padding: 1px 10px 0;
}

.MapFinderModule-container-parent-class .flexItem1.lastOne .ppc__customCheckbox label.MuiFormControlLabel-root:first-child, .MapFinderModule-container-parent-class .flexItem1.lastOne .ppc__customCheckbox .MuiFormControlLabel-root+.MuiFormControlLabel-root {
	margin-right: 20px;
}

.MapFinderModule-container-parent-class .customMapWrapperForm_oct_12 .flexItem1.lastOne .ppc__searchSelect_with_input .MuiFormControlLabel-label {
	font-size: 14px;
	color: rgb(19 49 89 / 82%);
}

/*  */

.customMapWrapperForm_oct_12 .drp_locations button.MuiButtonBase-root {
	padding: 6px 12px !important;
	font-size: 13px !important;
	height: auto !IMPORTANT;
	box-shadow: unset;
}

.customMapWrapperForm_oct_12 .drp_locations .MuiInputBase-root {
	height: auto;
	border-color: #3c7ef39c;
	padding: 5px;
}

.customMapWrapperForm_oct_12 .MuiFormControl-root.email__pophover__wr.drp_locations {
	margin: 10px 0 0!important;
}

.customMapWrapperForm_oct_12 .drp_locations span.MuiChip-label {
	font-size: 11px !important;
	padding: 5px 6px !important;
	line-height: 0;
}

.customMapWrapperForm_oct_12 .drp_locations .MuiSelect-select:focus {
	background: transparent !important;
}

.customMapWrapperForm_oct_12 .drp_locations .MuiSelect-outlined.MuiSelect-outlined em {
	font-size: 13px;
}

.customMapWrapperForm_oct_12 .MuiSelect-outlined.MuiSelect-outlined {
	padding: 0 25px 0 0;
}

.customMap .gmnoprint+div>div {
	margin: 5px 0 0 !important;
}

.customMap .gmnoprint+div>div>div {
	font-size: 13px !important;
	line-height: 27px !important;
}

.MapFinderModule-container-parent-class .flexItem1Left:not(.lastOne) .ppc__searchSelect_btn {
	width: 100px !important;
	font-size: 14px !important;
}

.MapFinderModule-container-parent-class .customMapWrapper h2 {
	font-size: 15px;
	padding-right: 15px;
	flex-shrink: 0;
}
/* Update Tag Modal Styles - Oct 24 */
.globalModalWrapper.ppc__search_modal.update_tag_list {
        max-width: 800px;
        width: 100%;
        height: calc(100% - 85px);
        min-width: unset !important;
}

.globalModalWrapper.ppc__search_modal.update_tag_list .modalBody.undefined {
        height: calc(100% - 70px);
}

.globalModalWrapper.ppc__search_modal.update_tag_list #globalModal {
        height: 100%;
}

.update_tag_list .tagList__checkbox_wrapper ul {
        display: flex;
        flex-wrap: wrap;
        max-height: 200px;
        overflow-y: auto;
}

.update_tag_list .tagList__checkbox_wrapper ul li {
        flex: 0 1 25%;
        margin: 0 0 10px;
}

.update_tag_list .ppc__searchSelect_with_input {
        align-items: flex-start;
}

/* Modal Masterpease start - Oct 24 */
.map__finder__tag__lists__wr__v2 .modalBody{
	height: auto !important;
	padding: 20px 0 !important;
}
.map__finder__tag__lists__wr__v2 .modalBody .map__finder__tag__lists__container__v2{
	height: calc(100vh - 200px);
	display: block !important;
	padding: 0 20px 0px 20px !important;
}
.map__finder__tag__lists__wr__v2 .map__finder__tag__lists__container__v2 .tagList__checkbox_wrapper span.MuiIconButton-root,
.map__finder__tag__lists__wr__v2 .map__finder__tag__lists__container__v2 .tagList__checkbox_wrapper label{
	color: #6c7887;
	font-size: 14px;
    font-weight: 700;
	margin-bottom: 3px;
	grid-gap: 8px;
}
.map__finder__tag__lists__wr__v2 .map__finder__tag__lists__footer__v2{
	border-top: 1px solid #ddd ;
}
.map__finder__tag__lists__wr__v2{
	max-width: 680px !important;
}
.map__finder__tag__lists__wr__v2 .map__finder__tag__lists__container__v2 .mpf-tag-select-all{
	border-bottom: 1px solid #ddd;
    margin: 0 10px 0px 0;
    padding-bottom: 8px;
}
.map__finder__tag__lists__wr__v2 .map__finder__tag__lists__container__v2 .mpf-tag-select-all label{
	grid-gap: 8px !important;
	color: var(--dark_blue);
	
}
.map__finder__tag__lists__wr__v2 .map__finder__tag__lists__container__v2 .mpf-tag-select-all span.MuiIconButton-root{
	color: var(--dark_blue);
}

.MapFinderModule-container-parent-class #change_disposition_dropdown{
	padding: 6px 0 6px 10px;
	-webkit-appearance:none;
	width:100%;
	border: 1px solid #c4c4c4;
}
.MapFinderModule-container-parent-class #change_disposition_dropdown_search{
	padding: 6px 0 6px 10px;
	-webkit-appearance:none;
	width:100%;
	border: 1px solid #c4c4c4;
}

/* Modal Masterpease end  */

@media (min-width: 1367px) {
	.ppc__searchSelect {
		width: calc(100% - 100px);
	}
	.flexItem1Left .ppc__searchSelect_with_input {
		flex: 1;
	}
	.customMapWrapperForm_oct_12 .flexRow .flexItem1:not(.lastOne) {
		flex-basis: 720px;
	}
	.customMapWrapperForm_oct_12 .flexRow .flexItem1 {
		flex: 0 1 calc(50% - 10px);
	}
	.customMapWrapperForm_oct_12 .flexRow .flexItem1.lastOne {
		flex-basis: calc(100% - 720px);
		padding-left: 30px;
	}
	.customMapWrapperForm_oct_12 .flexItem1Left {
		display: flex;
		align-items: center;
	}
}

@media (max-width: 1100px) {
	.customMapWrapperForm_oct_12 .flexRow {
		flex-direction: column-reverse;
		align-items: flex-start;
		padding: 15px;
	}
	.customMapWrapperForm_oct_12 .flexRow .flexItem1 {
		flex: 1;
		width: 100%;
	}
	.customMapWrapperForm_oct_12 .flexRow .flexItem1.lastOne {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #3c7ef38c;
	}
	.customMapWrapperForm_oct_12 .ppc__searchSelect_with_input p {
		margin-bottom: 0;
	}
}

@media (max-width: 1024px) {
	.instance__modal .globalModalWrapper {
		width: 650px;
	}
	
	.customMapWrapperForm_oct_12 .flexRow .flexItem1 {
		flex: 1;
		width: 100%;
	}

	.customMapWrapperForm_oct_12 .flexItem1Left {
		width: 100%;
	}

	.ppc__searchSelect_with_input { 
		flex: 1; 
	}
}