input {
    box-sizing: border-box !important;
}
.content-between {
	justify-content: space-between !important;
}
.items-center {
	align-items: center !important;
}
.flex {
	display: flex !important;
}
.border__bottom {
	border-bottom: 1.29px solid #e5e8ef;
	border-bottom: var(--border__bottom__1px);
}

.v2 .contact__info_lists.alt {
	display: flex;
	margin-top: 70px;
}
.v2 .contact__info_lists li {
	font-family: Poppins;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.01em;
	color: #546376;
	word-break: break-all;
	margin-right: 5px;
}
.v2 .contact__info_lists.alt li + li {
	margin-left: 10px;
}
.v2 .contact__info_lists li > span {
	margin-right: 10px;
	border: 1px solid #546376;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.v2 .contact__info_lists .each-item-icon {
	margin-left: auto;
	margin-right: 0;
	cursor: pointer;
}
.v2 .contact__info_lists.alt li > span {
	margin-right: 0;
    cursor: pointer;
}

.v2 .contact__info_lists.alt li > span > svg {
	margin-left: 0;
}

.v2 .contact__info_lists.alt svg {
	width: 16px !important;
}
.v2 .contact__info_lists.alt li:last-child {
	margin-left: auto;
}

.v2 .contactItem {
	background: #fff;
	border: 1px solid #e5e8ef;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 20px;
	flex: 1 1;
}
.v2 .left_contact {
	margin-right: 20px;
	width: 50%;
}

.v2 .contact__info_title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 20px;
	color: #133159;
}
.v2 .outcomeWrapper {
	margin-top: 10px;
	border-top: 2px solid #fff;
}
.v2 .outcomeWrapper {
	padding: 10px 0 0;
	display: inline-flex;
}
.v2 .outcomeWrapper.footerBtn {
	display: flex;
	justify-content: flex-end;
}
.v2 .outcomeWrapper.footerBtn .left__column.columnItem {
	max-width: 150px;
	text-align: center;
	border-radius: 6px;
}
.v2 .left__column {
	margin-right: 10px;
}
.v2 .addicon_collaps {
	display: flex;
	align-items: center;
}
.v2 .columnItem {
	flex: 1;
	position: relative;
}
.v2 .outcomeWrapper .collapsible {
	margin-bottom: 0 !important;
	box-shadow: unset;
	border: 0;
}

.v2 .columnItem .trigger__header {
	background: rgba(60, 126, 243, 0.1);
	border-radius: 10px;
	padding: 7.5px 20px;
}
.v2 .trigger__header {
	padding: 17.5px 20px;
	border-radius: 10px 10px 0 0;
	background: #fff;
	font-family: "Poppins";
	font-family: var(--poppins);
}

.v2 .trigger__agency .trigger__subtitle + span {
	display: inline-flex;
}

.v2 .ds__trigger_length {
	border-radius: 50%;
	background-color: #fff;
	min-width: 30px;
	text-align: center;
	justify-content: center;
	height: 30px;
	align-items: center;
	display: inline-flex;
}
.v2 .outcomeWrapper .trigger__subtitle {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 34px;
	display: flex;
	align-items: center;
	letter-spacing: 0.01em;
	color: #133159;
}
.v2 .columnItem .trigger__header .customCaret {
	transition: all 0.4s linear;
}
.v2 .trigger__header svg {
	display: inline-flex;
	align-items: center;
}

.v2 .trigger__agency .trigger__subtitle > svg {
	margin-right: 8px;
}

.v2 .addName__actions_wrapper {
	justify-content: center;
	margin-bottom: 15px;
}

.v2 .addName__actions button {
	border: 0;
	padding: 18px 16px;
	border-radius: 4px;
	min-width: 100px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	min-height: 50px;
	background: #bdbdbd;
	cursor: pointer;
	transition: all 0.3s linear;
}
.v2 .addName__actions {
	display: inline-flex;
	align-items: center;
}

.v2 .addName__actions button:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.v2 .addName__actions button:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.v2 .addName__actions button svg path {
	fill: #fff;
}

.v2 .addName__actions button:focus {
	background: rgb(60, 126, 243);
}

.v2 .contact__info_lists.alter li + li {
	margin-top: 20px;
}

.v2 .columnItem .trigger__header.expand .trigger__subtitle + svg {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
.v2 .columnItem .trigger__header .trigger__subtitle + svg {
	width: 12px;
	height: 5px;
}
.v2 .columnItem .trigger__header .trigger__subtitle + svg {
	transition: all 0.4s linear;
}

.v2 .defaultToggle.trigger__active .collapsible-body {
    opacity: 1;
    visibility: visible;
    max-height: 600px;
    overflow-y: auto;
}
.v2 .defaultToggle .collapsible-body {
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease;
    display: block!important;
    max-height: 0;
}
.v2 .collapsible-body {
    border-radius: 0 0 10px 10px;
    background: #fff;
    padding: 0;
    border-bottom: 1.29px solid #e5e8ef;
}
.v2 .columnItem .templateItems {
    margin: 20px;
    padding: 0; 
}

 .v2 .columnItem .tagsCustomAccordion .templateItems {
    margin: 10px 10px 0;
}
.v2 .contact__details_modal_wrapper .ds__loader {
    margin: 20px 0;
}
.v2 .contact__details_modal_wrapper .common__round_circle.customMargin10px {
    margin: 0 10px 10px;
}
.v2 .ds__templateItems.templateItems .ds__templateItems_li {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;
    flex-direction: column;
    padding: 12px;
    width: 100%;
} 
.v2 .ds__selectItem {
    display: flex;
    cursor: pointer;
}
.v2 .columnItem .common__round_circle {
    cursor: pointer;
}
.v2 .ds__template_topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.v2 .search__type_lists {
    max-height: 150px;
    height: 100%;
    padding: 10px 10px 10px 0;
}
.v2 .ds__value_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 8px;
}
.v2 .columnItem .templateItems .campaign-each-container .badge,
.v2 .columnItem .templateItems button {
    padding: 7px 10px;
    border: 0;
    background: rgba(60,126,243,.2784313725490196);
    border-radius: 5px;
    font-size: 14px;
    margin-left: auto;
}
.v2 .columnItem .templateItems .campaign-each-container .badge {
    height: auto;
    cursor: pointer;
    float: unset;
    color: #111;
}
.v2 li.common__round_circle svg {
    margin-right: 5px;
    cursor: pointer;
} 
.v2 .ds__value_wrapper svg {
    height: 100%!important;
}

.v2 .ds__value_wrapper .ds__value_item:first-child {
    right: 30px;
}
.v2 .ds__value_item {
    position: absolute;
    right: 0;
    /* top: 5px; */
    top: -25px;
    z-index: 3;
    cursor: pointer;
}
.v2 .columnItem .common__round_circle, .ds__add_tag_tem {
    display: inline-flex;
}
.v2 li.common__round_circle {
    display: inline-flex!important;
    align-items: center;
}
.v2 .common__round_circle {
    border: 1px solid #ddd;
    border-radius: 6px;
    margin: 0;
    padding: 6px 10px;
}
.v2 .ds__templateItems_li_edit {
    width: 100%!important;
}
.v2 .ds__templateItems_li_edit .input-field {
    margin: 0;
}
.v2 .ds__templateItems_li_edit .input-field input {
    height: unset!important;
    padding: 6px!important;
    border: 1px solid #ddd!important;
    border-radius: 6px;
    margin: 0;
    box-shadow: unset !important;
}

/* Modal Wrapper Styles */
.contact__details_modal_wrapper.globalModalWrapper {
    overflow: hidden;
    /* max-height: 700px; */
    /* height: 100%; */
    height: calc(100% - 80px);
}
.contact__details_modal_wrapper.globalModalWrapper #globalModal {
    height: 100%;
    position: relative;
}
.contact__details_modal_wrapper .modalBody {
    height: calc(100% - 70px);
    overflow-y: auto;
}
/* End */

.v2 .ds__add_tag_tem svg {
    font-size: 18px;
}

.v2 .columnItem .templateItems.ds__tags_template {
    /* margin: 10px; */
    padding-bottom: 0;
    /* height: 70px; */
    max-height: 70px;
}
.v2 ul.templateItems.ds__templateItems.awesome__scroll_bar.maxH100 {
    height: 182px;
    padding-bottom: 0;
}
.v2 .columnItem .templateItems.ds__tags_template li + li {
    margin: 10px;
}

.v2 .columnItem .templateItems.ds__tags_template button {
    margin: 0 5px 5px 0;
}
.v2 .columnItem .templateItems button {
    padding: 7px 10px;
    border: 0;
    background: rgba(60,126,243,.2784313725490196);
    border-radius: 5px;
    font-size: 14px; 
    margin-left: auto;
}

.v2 .columnItem .templateItems button span {
    margin: 2px;
}
.v2 .message__area_here.common__round_circle,
.v2 .ds__type_and_search input {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 8px;
    margin: 5px 0 10px;
    font-size: 15px;
    box-shadow: unset !important;
}
.v2 .message__area_here.common__round_circle:focus,
.v2 .ds__type_and_search input:focus {
   border: 1px solid #dddddd !important;
}

.v2 .noValid {
    color: #d00;
    font-size: 13px;
    margin-top: 3px;
}
.v2 .campaign-each-container {
    background: rgba(60,126,243,.1);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
}

.v2 .campaign_email {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .01em;
    color: #546376;
}
.v2 .columnItem .templateItems button {
    padding: 7px 10px;
    border: 0;
    background: rgba(60,126,243,.2784313725490196);
    border-radius: 5px;
    font-size: 14px;
}
.v2 .ds__campaign_list_item li:nth-child(2) {
    font-style: italic;
    font-size: 14px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}

.v2 .columnItem .templateItems .customFormControl {
    height: unset !important;
    padding: 7px 12px !important;
}

.v2 .columnItem .templateItems input {
    height: auto;
    margin: 0;
    /* border: 0 !important; */
    padding: 0;
}

.v2 .MuiInput-underline:before, .v2 .MuiInput-underline:after {
    display: none;
}

.update__deal_modal .trigger__modal_title {
    font-size: 16px;
    font-weight: 500;
    margin: 10px 0 5px;
    box-shadow: unset !important;
}

.update__deal_modal .trigger__modal_textField:focus {
    border: 1px solid #ccd2d2!important;
    box-shadow: unset !important;
}

.update__deal_modal .trigger__modal_textField {
    border-radius: 5px!important;
    padding-left: 15px!important;
    color: #133159;
    font-size: 14px!important;
    width: calc(100% - 15px)!important;
}
.update__deal_modal .customFormControl.alt .trigger__select .MuiSelect-select, .update__deal_modal .trigger__modal_textField {
    border: 1px solid #ccd2d2!important;
}


.v2 .addNote__lists {
    background: #fff;
    border: 1px solid #e5e8ef;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 10px 60px 50px;
    max-height: 312px;
    overflow-y: auto;
}

.v2 .note__blue_btn {
    display: flex;
    align-items: center;
    padding: 13px 15px;
    width: 136px;
    height: 50px;
    background: #3c7ef3;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .01em;
    color: #fff;
    cursor: pointer;
    border: 0!important;
    margin: 20px 0 20px auto;
}

.v2 .note__blue_btn svg {
    margin-right: 10px;
}
.v2 .noteHeader {
    padding: 0 0 25px;
}

.v2 .noteHeader .note__avatar {
    border: 6px solid #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
}

.v2 .note__avatar-alt {
    justify-content: center;
    align-items: center;
    display: flex;
}
.v2 .userInfo .trigger__modal_title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: #133159;
}

.v2 .each-item-edit input {
    border-radius: 6px!important;
    height: 30px!important;
    margin: 0!important;
    box-shadow: unset!important;
    border: 1px solid #ddd!important;
    padding-left: 10px!important;
    padding-right: 65px!important;
    font-size: 14px!important;
}

.v2 .each-item-edit {
    position: relative;
}

.v2 .each-item-edit span {
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 4px;
}
.v2 .each-item-edit span+span {
    right: 10px;
}
.v2 .trigger__modal_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}



/* Note Lists */

.contact__details_modal_wrapper .noteList_header {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #133159;
    padding: 20px 20px 13px;
    border-bottom: 1px solid #e5e8ef;
}

.contact__details_modal_wrapper .editIcon {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: .01em;
    color: #3c7ef3;
    cursor: pointer;
}

 .contact__details_modal_wrapper span.editIcon.leftAlignIcon {
    border: 1px solid #546376;
    position: absolute;
    left: -40px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    top: 0;
}

.contact__details_modal_wrapper .noteList {
    background: #f6f7fb;
    border-radius: 10px 10px 10px 0;
    position: relative;
}

.contact__details_modal_wrapper .noteList+.noteList {
    margin-top: 20px;
}

.contact__details_modal_wrapper .ml24px {
    margin-left: 24px;
}
.contact__details_modal_wrapper .noteList_content, 
.contact__details_modal_wrapper .noteList_content>* {
    padding: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #546376;
}

.contact__details_modal_wrapper .note__blue_btn {
    width: auto!important;
}

.contact__details_modal_wrapper .addNote__lists .noteList_content p {
    padding: 0!important;
    margin: 0 !important;
}


/* Global Loader Style */
.contact__details_modal_wrapper .addNote__lists {
    background: #fff;
    border: 1px solid #e5e8ef;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 10px 60px 50px;
    max-height: 295px;
    overflow-y: auto;
}

.loader-container .contact__info_wrapper {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-container {
    height: 100%;
}

.appointment_list_item .date_author_title{
    display: flex;
    flex-direction: column;
}

.react-confirm-alert-overlay{
    z-index: 99999 !important;
}





/* design modify */
.global-contact-details-update-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.5; /* Firefox */
}
  
.global-contact-details-update-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: 0.5;
}
  
.global-contact-details-update-input::-ms-input-placeholder { /* Microsoft Edge */
    opacity: 0.5;
}

.global-contact-details-input-custom-field{
    color: #133159 !important
}

.empty-tag-list-contact-details{
    justify-content: center;
    align-items: center;
    display: flex !important;
    /* height: 100%; */
    height: 70px;
    cursor: default;
}

.campagin-search-height{
    height: 40px !important;
    padding: 0px 10px !important;
}

.source-container-contact-details .customFormControl{
    border: none !important;
}
