.modalWrapper {
    display: grid;
    place-content: center;
}

.modalInner {
    width: 900px;
    height: fit-content;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
}

.modalInner:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.headerWrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 16px 24px !important;
    border-bottom: 1px solid rgba(148, 157, 178, 0.24) !important;
}

.headerTitle {
    margin: 0;
    font-family: Poppins, Arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #1c1b1f;
    font-weight: 500;
}

.headerClose {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
}

.headerClose:hover {
    background-color: rgba(237, 0, 57, 0.03);
}

.headerClose svg path {
    color: red !important;
}

.modalBody {

}

.paddingWrp {
    padding: 24px;
}

.modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.dispositionSelectInline {
    display: flex;
    align-items: center;
    width: 100%;
}

.newDisposition {

}

.dispositionIcons {
    max-height: 124px;
    overflow-y: auto;
    background: #ddd;
    padding: 10px 10px 10px 5px;
    border-radius: 5px;
    margin-top: 20px;
}

.dispositionIcon {
    margin: 0 5px;
    border: 5px solid #c5c5c5;
    border-radius: 50%;
    transition: all .4s linear;
}

.selectedDispositionIcon {
    border-color: var(--accent-color);
}