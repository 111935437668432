.tabWrapper {
    display: flex;
    gap: 25px;
}

.tabTitle {
    font-size: 16px;
    font-weight: bold;
    color: #133159a5;
    cursor: pointer;
}

.tabActive {
    color: #6070ed !important;
    border-bottom: 2px solid #6070ed;
}

.headerLeftSide {
    display: flex;
    align-items: center;
}

.headerRightSide {
    display: flex;
    align-items: center;
    gap: 10px;
}

.headerRightSide span {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.enhanceDataButton {
    cursor: pointer;
    margin-left: 50px;
}

.enhanceDataButton svg {
    border-style: outset;
    border-width: 2px;
    border-radius: 4px;
    border-color: #3a59bd;
    transition: background-color 0.3s ease, transform 0.2s ease, border-color 0.2s ease;
}

.enhanceDataButton svg:hover {
    background-color: #2980b9;
}

.enhanceDataButton svg:active {
    transform: scale(0.95);
    border-color: #3498db;
}

