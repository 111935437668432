.global__add_appointment_container.addAppointment_wrapper .trigger__modal_title {
	color: #133159;
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 10px;
}
.global__add_appointment_container .ds__mb0Space {
	margin-bottom: 0 !important;
}
.trigger__modal_textField,
.addAppointment_wrapper select {
	height: 45px;

	border-radius: 5px !important;
	padding: 10px !important;
	color: #133159;
	font-size: 14px !important;
	width: calc(100% - 15px) !important;
	border: 1px solid #ccd2d2 !important;
	width: 100% !important;
}

.addAppointment_wrapper select {
	background: transparent;
}

.ds__test.mt20pxSpaceTitle {
	margin-top: 20px !important;
}
.ds__test {
	display: inline-flex;
	align-items: center;
	margin-top: 6px;
	cursor: pointer;
}

.ds__test > svg {
	margin-right: 10px;
}
