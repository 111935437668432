.empty-container-contact-details{
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accent-bg-opacity:hover{
    background-color: var(--accent-color-opacity1) !important;
}

/* Map Finder - Contact Details Modal */
.images__wrapper {
    max-height: 113px;
    overflow-y: auto; 
    background: #ddd;
    padding: 10px 10px 10px 5px;
    border-radius: 5px;
}
.v3__contact_first_steps .twoColumns .MuiFormGroup-root {
    width: calc(100% - 143px);
}

.contact__steps_wrapper .v3__contact_first_steps .twoColumns .mpf-select .MuiInputBase-input {
    box-sizing: border-box;
    padding: 9px 10px;
    font-size: 16px !important;
    line-height: 30px !important;
    color: rgba(19, 49, 89, 0.65) !important;
}

.contact__steps_wrapper .v3__contact_first_steps .twoColumns .mpf-select .MuiInputBase-input em {
    font-style: normal;
}

.v3__contact_first_steps .second__step__area__select.assign__stage_select_wrapper .MuiSelect-select:focus {
    height: 50px !important;
}
.v3__contact_first_steps .twoColumns .ppc__blue_btn {
    margin-bottom: -5px;
}

.address_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

span.add__clearIcon {
    background: #d00;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
    line-height: 15px;
    color: #fff;
    min-width: 45px;
    cursor: pointer;
}

.address_search input {
    width: calc(100% - 45px);
    min-height: 45px;
    outline: 0;
    border-top-left-radius: 5px;
    border-color: #ddd;
    border-bottom-left-radius: 5px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
}

.address_search input::placeholder {
    color: #4f373f;
}

.images__wrapper img {
    margin: 0 5px;
    border: 5px solid #c5c5c5;
    border-radius: 50%;
    transition: all .4s linear;
    -webkit-transition: all .4s linear;
    -moz-transition: all .4s linear;
    -o-transition: all .4s linear;
    
}

.images__wrapper img.selected-new-disposition-new-contact {
    border-color: var(--accent-color);
}